<template>
  <div class="flex direction center bgSea item_page">
    <div class="box">
      <div class="top1 center flex">
        <div class="title flex direction center">My friends</div>
        <div class="off" @click="off()"><img :src="imgs.off" alt="" /></div>
      </div>
      <div class="top">
        <div class="avatar"><img :src="avatar" alt="" /></div>
        <div class="info">
          <div class="infoTop">
            {{ item?.nickname }}
            <!-- <div class="gift">
              <img :src="imgs.icon_achievement_full" alt="" /> <span>0</span>
            </div> -->
          </div>
          <div class="infoBottom">
            <div class="age">
              <img v-if="item.gender === 1" :src="imgs.men_full" alt="" />
              <img v-else :src="imgs.women_full" alt="" />
              &ensp;{{ item.age }}
            </div>
            <div class="country">
              <flag class="flag" :country="item.nation" size="normal" />&ensp;
              <span class="nation">
                {{ formatNation(item?.nation) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <img src="" alt="" />
        Achievements:{{ achiList?.length }}
      </div>
      <div class="achiTable">
        <div class="achiItem" v-for="(item, index) in achiList" :key="item.id">
          <img class="itemImg" src="/imgs/bottleType/bottle.png" alt="" />
          <div>
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="delete btnFlex" @click="deleteBtn()">
          <img :src="imgs.delete_btn" alt="" />
        </div>
        <div class="telegram btnFlex" @click="handleTg">Telegram</div>
      </div>
    </div>
    <van-dialog v-model:show="showDelete" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showDelete = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.sendBottle_full" alt="" />
        <div class="sendTitle">Delete Friend</div>
        <div class="sendContext">
          Are you sure you want to delete this friend?
        </div>
        <div class="sendBtn" @click="deleteFriend">Git it</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import flag from 'vue-country-flag-next'
import { friendListRef } from '@/config/common/total'
import { getAvatar } from '@/config/common/dic'
export default {
  components: {
    flag,
  },
  data() {
    return {
      showDelete: false,
      imgs: this.$store.state.sourceMsg,
      value: '',
      achiList: [
        // { id: 0, title: 'To fish out 20 bottles' },
        // { id: 0, title: 'To fish out 30 bottles' },
        // { id: 0, title: 'To fish out 40 bottles' },
        // { id: 0, title: 'To fish out 50 bottles' },
        // { id: 0, title: 'To fish out 60 bottles' },
        // { id: 0, title: 'To fish out 70 bottles' },
      ],
    }
  },
  props: ['item'],
  created() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#F7F6F6'
    this.getAchList()
  },
  mounted() {},
  beforeUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ACECF9'
  },
  computed: {
    avatar() {
      return getAvatar(this.item.tgid)
    },
  },
  methods: {
    deleteBtn() {
      this.showDelete = true
    },
    off() {
      this.$emit('close')
    },
    deleteFriend() {
      this.$api.deleteFriend(this.item.tgid).then((res) => {
        if (res.code === 0) {
          friendListRef.value = friendListRef.value.filter(
            (item) => item.tgid !== this.item.tgid
          )
          this.showDelete = false
          this.$emit('close')
          return showToast('Delete success')
        }
        showToast(res?.message)
      })
    },
    handleTg() {
      // 好友todo
      Telegram.WebApp.openTelegramLink(`https://t.me/${this.item?.username}`)
    },
    formatNation(nation) {
      return this.$g.getCountryName(nation)
    },
    getAchList() {
      this.$api.getUserAchievement(this.item.tgid).then((res) => {
        if (res.code === 0) {
          this.achiList = res?.data?.tasks.map((item) => {
            return {
              title: item.title,
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  width: 100%;
}
.box {
  width: 100%;
}
.top1 {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 1rem;
  height: 2rem;
  background: #ffffff;
  width: 100%;
  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .tab {
    width: 90%;
    margin: 0 auto;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-around;
    div {
      height: 1.4rem;
      width: 4rem;
      color: #8698b7;
    }
  }
  .off {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
    img {
      width: 100%;
    }
  }
}
.top {
  background-color: #fff;
  border-radius: 0.5rem;
  width: 90%;
  margin: 0 auto;
  margin-top: 3.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  .avatar {
    width: 3.9rem;
    height: 3.9rem;
    margin: 0 0.5rem;
    img {
      width: 3.9rem;
      height: 3.9rem;
      border-radius: 0.5rem;
      background: url('/imgs/bottleType/drift_sea_logo.jpg');
      background-size: contain;
      z-index: 1;
    }
  }
  .info {
    height: 3.9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .infoTop {
      font-size: 1rem;
      display: flex;
      align-items: center;
      .gift {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        font-size: 0.8rem;
        color: #8698b7;
        img {
          width: 0.85rem;
          height: 0.8rem;
          margin-right: 0.25rem;
        }
      }
    }
    .infoBottom {
      display: flex;
      font-size: 0.8rem;
      .age {
        background: #f0f3f8;
        width: 3.3rem;
        height: 1.5rem;
        border-radius: 0.65rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        img {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
      .country {
        background: #eaf9fc;
        min-width: 5.6rem;
        height: 1.5rem;
        border-radius: 0.65rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #39cce9;
        .flag {
          margin-bottom: 1px;
          border-radius: 0.25rem;
          box-shadow: 0rem 0rem 0.25rem gray;
        }
        .nation {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 30vw;
        }
      }
    }
  }
}
.title {
  width: 85%;
  margin: 0.3rem auto;
  display: flex;
}
.achiTable {
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0.5rem;
  .achiItem {
    margin: 0 1rem;
    display: flex;
    align-items: center;
    height: 2.5rem;
    border-bottom: 1px solid #f1f1f1;
    img {
      margin-right: 1rem;
      width: 1.1rem;
      height: 1.45rem;
    }
  }
}
.bottom {
  background-color: #f7f6f6;
  z-index: 11;
  width: 100%;
  margin: 0 auto;
  height: 4rem;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  .delete {
    height: 3rem;
    width: 5rem;
    background-color: #fff;
    border: 1px solid #39cce9;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .telegram {
    height: 3rem;
    width: 11rem;
    background-color: #39cce9;
    border-radius: 0.5rem;
    color: white;
  }
}
.btnFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: 0rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;
    img {
      width: 100%;
    }
  }
  .sendImg {
    width: 3.55rem;
    height: 4.65rem;
  }
  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }
  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }
  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}
</style>
