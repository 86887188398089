<template>
  <div class="flex direction center bgSea pro_page">
    <div class="box">
      <div class="top1 center flex">
        <div class="title flex direction center">My Profile</div>
      </div>
      <div class="top">
        <div class="avatar">
          <img class="avatarItem" :src="userAvatar" alt="" />
          <img @click="editInfo" class="edit" :src="imgs.edit" alt="" />
        </div>
        <div class="info">
          <div class="infoTop">
            {{ userInfo?.nickname }}
            <!-- <div class="gift">
              <img :src="imgs.point" alt="" /> {{ userInfo.level }}
            </div> -->
          </div>
          <div class="infoBottom">
            <div class="age">
              <img
                v-show="Number(userInfo.gender) === 1"
                :src="imgs.men_full"
                alt=""
              />
              <img
                v-show="Number(userInfo.gender) === 2"
                :src="imgs.women_full"
                alt=""
              />
              &ensp;{{ userInfo.age }}
            </div>
            <div class="country">
              <flag
                class="flag"
                :country="userInfo.nation"
                size="normal"
              />&ensp;
              <div class="countryName">
                {{ $g.getCountryName(userInfo.nation) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <div class="achiTitle">
          <img :src="imgs.title_front" alt="" />
          Achievements:{{ achievementList.length }}
        </div>
        <div class="more" @click="goMore()">More >></div>
      </div>
      <div class="achiTable">
        <div
          class="listItem"
          v-for="(item, index) in achievementList"
          :key="index"
        >
          <div class="itemLeft">
            <div class="itemImg">
              <img src="/imgs/bottleType/bottle.png" alt="" />
            </div>
            <div class="itemInfo">
              <div class="itemSend">
                To fish out {{ item?.target?.number }} bottles
              </div>
              <div class="itemWidth">
                <div
                  :style="{
                    width:
                      item?.target?.completion_num >= item?.target?.number
                        ? '100%'
                        : (item?.target?.completion_num /
                            item?.target?.number) *
                            100 +
                          '%',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyTable" @click="copy">
        <div style="font-size: 0.8rem">SUPPORT ID (press to copy)</div>
        <div style="font-size: 0.6rem">{{ supportId }}</div>
      </div>
    </div>
    <van-action-sheet class="changeModel" v-model:show="showEdit">
      <div class="topTip"></div>
      <div class="gender">
        <div class="genderTitle">gender</div>
        <div class="genderList">
          <div
            class="genderItem"
            v-for="item in genderList"
            :key="item.id"
            @click="changeGender(item, item.id)"
            :class="{ active: activeGender === item.id }"
          >
            <img v-if="activeGender === item.id" :src="item.imgFull" alt="" />
            <img v-else :src="item.img" alt="" />
            {{ item.gender }}
          </div>
        </div>
      </div>
      <div class="gender">
        <div class="genderTitle">Nation</div>
        <div class="genderList">
          <div class="flagAndCountry" @click="changeCountry(item, index)">
            <flag
              class="flag"
              :country="this.editForm.countryCode"
              size="normal"
            />
            <div>{{ editForm.country }}</div>
          </div>
        </div>
      </div>
      <div class="userAge">
        <div class="ageTitle">age</div>
        <div class="ageItem">
          <div class="num">{{ editForm.age }}</div>
          <van-slider
            style="height: 0.4rem; width: 80%"
            :min="1"
            :max="99"
            class="slide"
            v-model="editForm.age"
          />
        </div>
      </div>
      <div class="confirm" @click="Revise">
        <van-loading color="#fff" v-if="loadingRevise" />
        <div class="confirmReal" v-else>
          <div>Revise</div>
          <div style="font-size: 0.7rem"><img :src="imgs.coin" alt="" />10</div>
        </div>
      </div>
    </van-action-sheet>
    <selectCounty
      v-if="showSelectCounty"
      @selectCountry="selectCountry"
      @closeCountry="closeCountry"
    ></selectCounty>
    <van-dialog v-model:show="isShowNavigateShop" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="isShowNavigateShop = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.sendBottle_full" alt="" />
        <div class="sendTitle">not enough gold</div>
        <div class="sendContext">
          You don't have enough gold. <br />
          Would you like to go to the store to purchase more?<br />
        </div>
        <div class="sendBtn" @click="handleConfirmNavagateShop">Go</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import selectCounty from '../../components/Select/country.vue'
import { showToast } from 'vant'
import flag from 'vue-country-flag-next'
import {
  userTotalInfo,
  achieveUserListRef,
  achieveListRef,
  userAvatarRef,
} from '@/config/common/total'
import { getAvatar, getTgInitData } from '@/config/common/dic'
export default {
  components: {
    flag,
    selectCounty,
  },
  data() {
    return {
      supportId: '',
      activeGender: 1,
      nowCount: 7,
      userInfo: {},
      editForm: {},
      showEdit: false,
      showDelete: false,
      showSelectCounty: false,
      showToast: false,
      imgs: this.$store.state.sourceMsg,
      loadingRevise: false,
      isShowNavigateShop: false,
      value: '',
      achiList: [
        { id: 0, count: 1 },
        { id: 0, count: 3 },
        { id: 0, count: 5 },
        { id: 0, count: 10 },
        { id: 0, count: 20 },
        // {id:0,count:30},
      ],
      achievementList: [],
      genderList: [
        {
          id: 1,
          gender: 'man',
          img: this.$store.state.sourceMsg.men,
          imgFull: this.$store.state.sourceMsg.men_full,
        },
        {
          id: 2,
          gender: 'woman',
          img: this.$store.state.sourceMsg.women,
          imgFull: this.$store.state.sourceMsg.women_full,
        },
      ],
      userAvatar: '',
    }
  },
  computed: {
    // supportId() {
    //   return userTotalInfo.value?.token
    // },
  },
  created() {
    this.supportId = userTotalInfo.value?.token
    this.userInfo = userTotalInfo.value
    this.userAvatar =
      userAvatarRef.value ||
      getAvatar(this.userInfo?.tgid || this.$g.getStorage('userId'))
    console.log(this.userAvatar, 8888)
    Telegram.WebApp.BackButton.show()
    Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push({ path: '/' })
    })
    Telegram.WebApp.setHeaderColor('#fff')
    document.getElementsByTagName('body')[0].style.backgroundColor = '#F7F6F6'
    document.body.scrollTop = 0
    this.getUserInfo()
    this.getAchievementTask()
    // console.log(getTgInitData('username'), 'getTgInitData')
  },
  mounted() {},
  beforeUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ACECF9'
  },
  computed: {
    // userAvatar() {
    //   return asyncGetAvatar(this.userInfo?.tgid)
    // },
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      // 获取用户信息
      this.$api
        .getUserInfo()
        .then(async (res) => {
          if (res.code === 0) {
            this.userInfo = res.data
            this.userAvatar =
              userAvatarRef.value ||
              getAvatar(this.userInfo?.tgid || this.$g.getStorage('userId'))
            userTotalInfo.value = res.data
            this.editForm.age = Number(res.data.age)
            this.editForm.countryCode = res.data.nation
            this.editForm.country = this.$g.getCountryName(res.data.nation)
            this.editForm.gender = Number(res.data.gender)
            console.log(this.editForm)

            this.activeGender = Number(res.data.gender)
            this.$g.setStorage('userInfo', JSON.stringify(res.data))
          }
        })
        .catch((err) => {
          console.log('err=========================', err)
        })
      this.userInfo = JSON.parse(this.$g.getStorage('userInfo'))
    },
    getAchievementTask() {
      if (achieveListRef.value.length) {
        this.achievementList = achieveUserListRef.value
        return
      }
      this.$api.achievementTask().then((res) => {
        if (res.code === 0) {
          achieveListRef.value = res?.data?.tasks
            .map((item) => {
              item.award = item?.reward[0]?.number
              item.status = this.getStatus(item)
              item.url = item?.icon_url || imgs.bottle
              return item
            })
            .filter((item) => item.status === 2)
          this.achievementList = achieveUserListRef.value
          console.log(this.achievementList, 'this.achievementList->>>>')
          return
        }
        return showToast(res?.message)
      })
    },
    getStatus(item) {
      //['Go to', 'Receive', 'Finish'] 0 1 2
      if (!item?.target?.is_completion_target) {
        return 0
      }
      if (
        item?.target?.is_completion_target &&
        !item?.target?.is_reward_collection
      ) {
        return 1
      }
      if (
        item?.target?.is_completion_target &&
        item?.target?.is_reward_collection
      ) {
        return 2
      }
    },
    editInfo() {
      this.showEdit = true
    },
    changeGender(item, index) {
      this.activeGender = index
    },
    goMore() {
      this.$router.push('/achievement')
    },
    changeCountry() {
      this.showSelectCounty = true
      this.showEdit = false
    },
    changeCountry() {
      this.showSelectCounty = true
      this.showEdit = false
    },
    selectCountry(item) {
      this.editForm.country = item.name
      this.editForm.countryCode = item.code
      this.showSelectCounty = false
      this.showEdit = true
    },
    closeCountry() {
      this.showSelectCounty = false
      this.showEdit = true
    },
    Revise() {
      this.loadingRevise = true
      this.editForm.gender = this.activeGender
      if (this.userInfo.gold < 10) {
        this.isShowNavigateShop = true
      }
      console.log(this.editForm)
      let avatar = this.userInfo.avatar // 获取头像
      let data = {
        nation: this.editForm.countryCode,
        age: this.editForm.age,
        gender: this.editForm.gender,
        avatar: avatar,
        // username: getTgInitData('username'),
      }
      // 修改个人信息
      this.$api
        .editUser(data)
        .then((res) => {
          if (res.code === 0) {
            this.userInfo = res.data
            this.$g.setStorage('userInfo', JSON.stringify(res.data))
            this.showEdit = false
            this.loadingRevise = false
            showToast('Revise success')
          } else {
            showToast(res?.message || 'Revise failed')
            console.log('errorCode')
          }
        })
        .catch((err) => {
          console.log('err=========================', err)
        })
    },

    copy() {
      let textToCopy = this.supportId
      // 将文本内容写入剪贴板
      navigator.clipboard.writeText(textToCopy)
      showToast('Copied')
      // this.showToast = true
    },
    handleConfirmNavagateShop() {
      this.isShowNavigateShop = false
      this.$router.push('/shopping')
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  width: 100%;
}
.box {
  width: 100%;
}
.top1 {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 1rem;
  height: 2rem;
  background: #ffffff;
  width: 100%;
  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .tab {
    width: 90%;
    margin: 0 auto;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-around;
    div {
      height: 1.4rem;
      width: 4rem;
      color: #8698b7;
    }
  }
  .off {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    width: 2rem;
    height: 2rem;
    img {
      width: 100%;
    }
  }
}
.top {
  background-color: #fff;
  border-radius: 0.5rem;
  width: 90%;
  margin: 0 auto;
  margin-top: 3.5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  .avatar {
    width: 3.9rem;
    height: 3.9rem;
    margin: 0 0.5rem;
    position: relative;
    .avatarItem {
      width: 3.9rem;
      height: 3.9rem;
      background: url('/imgs/bottleType/drift_sea_logo.jpg');
      background-size: contain;
      z-index: 1;
      border-radius: 0.5rem;
    }
    .edit {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      bottom: 0;
      right: -0.5rem;
    }
  }
  .info {
    margin-left: 0.5rem;
    height: 3.9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .infoTop {
      font-size: 1rem;
      display: flex;
      align-items: center;
      .gift {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        font-size: 0.8rem;
        color: #8698b7;
        img {
          width: 0.85rem;
          height: 0.8rem;
          margin-right: 0.3rem;
        }
      }
    }
    .infoBottom {
      display: flex;
      font-size: 0.8rem;
      .age {
        background: #f0f3f8;
        width: 3.3rem;
        height: 1.5rem;
        border-radius: 0.65rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        img {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
      .country {
        background: #eaf9fc;
        padding: 0 0.5rem;
        height: 1.5rem;
        border-radius: 0.65rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #39cce9;
        .flag {
          margin-bottom: 1px;
          border-radius: 0.25rem;
          box-shadow: 0rem 0rem 0.25rem gray;
        }
        .countryName {
          max-width: 4rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.title {
  width: 85%;
  margin: 0.3rem auto;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 0.4rem;
    height: 1rem;
    margin-right: 0.3rem;
  }
  .achiTitle {
    display: flex;
    align-items: center;
  }
  .more {
    color: #39cce9;
    font-size: 0.8rem;
  }
}
.achiTable {
  width: 90%;
  margin: 0 auto;
  border-radius: 0.5rem;
  padding-bottom: 10rem;
  .listItem {
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .itemLeft {
      display: flex;
      .itemImg {
        display: flex;
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 100%;
        background: #b2eff9;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        img {
          width: 1.1rem;
          height: 1.45rem;
        }
      }
      .itemInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 0.7rem;
        text-align: start;
        .itemSend {
          font-weight: bold;
        }
        .itemWidth {
          width: 7.5rem;
          height: 0.5rem;
          border-radius: 1rem;
          background: #eaf9fc;
          border: 1px solid #39cce9;
          position: relative;
          div {
            width: 3rem;
            height: 0.5rem;
            border-radius: 1rem;
            background: #39cce9;
          }
          span {
            font-size: 0.6rem;
            position: absolute;
            bottom: 0;
            left: 40%;
          }
        }
      }
    }
    .itemRight {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 2.8rem;
      .itemCoin {
        display: flex;
        align-items: center;
        height: 1rem;
        background: #daf6fa;
        border-radius: 0.5rem;
        font-size: 0.7rem;
        width: 3rem;
        font-weight: bold;
        img {
          width: 1rem;
          height: 1rem;
        }
      }
      .receive {
        background: #39cce9;
        width: 3.8rem;
        height: 1.5rem;
        border-radius: 0.5rem;
        color: white;
        font-size: 0.8rem;
        line-height: 1.5rem;
      }
    }
  }
}
.bottom {
  background-color: #f7f6f6;
  z-index: 11;
  width: 100%;
  margin: 0 auto;
  height: 4rem;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  .delete {
    height: 3rem;
    width: 5rem;
    background-color: #fff;
    border: 1px solid #39cce9;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
  }
  .telegram {
    height: 3rem;
    width: 11rem;
    background-color: #39cce9;
    border-radius: 0.5rem;
    color: white;
  }
}
.btnFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -0.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;
    img {
      width: 100%;
    }
  }
  .sendImg {
    width: 3.55rem;
    height: 4.65rem;
  }
  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }
  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }
  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}
.changeModel {
  .topTip {
    top: 0.5rem;
    width: 4rem;
    height: 0.3rem;
    margin: 0.5rem auto;
    background-color: #dddddd;
    border-radius: 0.5rem;
  }
  .changeTitle {
    text-align: start;
    margin: 1rem 1rem 0.5rem 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: #39cce9;
  }
  .bottleItem {
    padding: 0 0.8rem;
    display: flex;
    justify-content: space-between;
    width: 83%;
    margin: 0 auto;
    background-color: #f0f3f8;
    border-radius: 0.5rem;
    height: 4rem;
    margin-bottom: 0.9rem;
  }
  .bottleIcon {
    position: relative;
    width: 18%;
    height: 4rem;
    display: flex;
    align-items: center;
    img {
      width: 1.5rem;
    }
    div {
      position: absolute;
      top: 0.5rem;
      right: 0rem;
      color: #39cce9;
    }
  }
  .bottleInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 79%;
    line-height: 1.2rem;
    div {
      text-align: start;
    }
    .bottleName {
      font-size: 1rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .coin {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;
        img {
          width: 0.8rem;
          margin-right: 0.25rem;
        }
      }
    }
    .bottleDesc {
      font-size: 0.56rem;
      word-wrap: normal;
      color: #8698b7;
    }
  }
  .confirm {
    width: 80%;
    margin: 0 auto;
    height: 2.7rem;
    background-color: #39cce9;
    margin-bottom: 1rem;
    color: #fff;
    font-size: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    .confirmReal {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
    div {
      display: flex;
      align-items: center;
      font-size: 1rem;
      img {
        width: 0.7rem;
        height: 0.7rem;
        margin-right: 0.2rem;
      }
    }
  }
}
.gender {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  margin-top: 0.5rem;
  .genderTitle {
    text-align: start;
    margin-bottom: 0.5rem;
  }
  .genderList {
    display: flex;
    align-items: center;
  }
  .genderItem {
    width: 5rem;
    height: 1.8rem;
    background-color: #f0f3f8;
    color: #8698b7;
    line-height: 1.8rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    img {
      width: 0.7rem;
      margin-right: 0.3rem;
    }
  }
  .flagAndCountry {
    padding: 0 0.5rem;
    height: 1.8rem;
    background-color: #f0f3f8;
    color: #8698b7;
    line-height: 1.8rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    .flag {
      margin-right: 0px;
      border-radius: 0.25rem;
      box-shadow: 0rem 0rem 0.25rem rgb(0, 0, 0);
    }
  }
}
.userAge {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  .num {
    margin-right: 0.5rem;
  }
  .ageItem {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .ageTitle {
    text-align: start;
    margin-bottom: 0.5rem;
    width: 100%;
  }
}
.copyTable {
  position: fixed;
  bottom: 5rem;
  left: 1rem;
  background: #e6e6e6;
  border-radius: 0.5rem;
  width: 90%;
  color: #8698b7;
  padding: 0.5rem 0;
}

.active {
  border: 1px solid #5ed4ec;
}
</style>
