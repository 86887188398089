<template>
  <div class="aci_page bgSea">
    <div>
      <div class="top">
        <div class="title">Achievement</div>
      </div>
      <div class="list">
        <div
          class="listItem"
          v-for="(item, index) in achievementList"
          :key="index"
        >
          <div class="itemLeft">
            <div class="itemImg">
              <img :src="item.url" alt="" />
            </div>
            <div class="itemInfo">
              <div class="itemSend">{{ item?.title }}</div>
              <div class="itemWidth">
                <div
                  class="procWidth"
                  :style="{
                    width:
                      item?.target?.completion_num >= item?.target?.number
                        ? '100%'
                        : (item?.target?.completion_num /
                            item?.target?.number) *
                            100 +
                          '%',
                  }"
                ></div>
                <div class="proc">
                  {{
                    MinCount(
                      item?.target?.completion_num,
                      item?.target?.number
                    )
                  }}/{{ item?.target?.number }}
                </div>
              </div>
            </div>
          </div>
          <div class="itemRight">
            <div class="itemCoin">
              <img :src="imgs.coin" alt="" />+{{ item.award }}
            </div>
            <div
              class="receive"
              @click="handleBtnClick(item)"
              :class="{ finish: item.status == 2 }"
            >
              {{ statusList[item.status] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ach-pop">
      <van-toast
        v-model:show="isReceiveShow"
        :icon="imgs.coin"
        :message="`+ ${awardCoin}`"
        style="padding: 0"
      >
      </van-toast>
    </div>
  </div>
</template>

<script>
import { showToast } from 'vant'
import { getUserInitInfo, achieveListRef } from '@/config/common/total'
export default {
  data() {
    return {
      isReceiveShow: false,
      awardCoin: 10,
      imgs: this.$store.state.sourceMsg,
      achievementList: [],
      statusList: ['Go to', 'Receive', 'Finish'],
      reqFlag: false,
    }
  },
  created() {
    Telegram.WebApp.BackButton.show()
    Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push({ path: '/' })
    })
    Telegram.WebApp.setHeaderColor('#fff')
    document.getElementsByTagName('body')[0].style.backgroundColor = '#F7F6F6'
    this.achievementList = achieveListRef.value
    this.getAchievementTask()
  },
  mounted() {},
  beforeUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ACECF9'
  },
  methods: {
    handleBtnClick(item) {
      //['Go to', 'Receive', 'Finish'] 0 1 2
      const { status } = item
      switch (status) {
        case 0:
          const regex = /^[\/a-zA-Z]/
          const url = item?.payload?.jump_url
          let jumpurl = '/'
          if (regex.test(url)) {
            jumpurl = url
          }
          return this.$router.push({ path: jumpurl })
          break
        case 1:
          if (this.reqFlag) {
            return
          }
          this.reqFlag = true
          this.awardCoin = item?.award
          this.$api.getReward(item.id).then((res) => {
            if (res.code === 0) {
              this.isReceiveShow = true
              this.$nextTick(() => {
                this.getAchievementTask()
                getUserInitInfo()
                this.reqFlag = false
              })
              return
            }
            this.reqFlag = false
          })
          break
        case 2:
          return
          break
      }
    },
    getAchievementTask() {
      this.$api.achievementTask().then((res) => {
        if (res.code === 0) {
          this.achievementList = res?.data?.tasks.map((item) => {
            item.award = item?.reward[0]?.number
            item.status = this.getStatus(item)
            item.url = item?.icon_url || imgs.bottle
            return item
          })
          achieveListRef.value = this.achievementList
          console.log(this.achievementList, 'this.achievementList->>>>')
          return
        }
        return showToast(res?.message)
      })
    },
    MinCount(n, o) {
      return Math.min(n, o)
    },
    getStatus(item) {
      //['Go to', 'Receive', 'Finish'] 0 1 2
      if (!item?.target?.is_completion_target) {
        return 0
      }
      if (
        item?.target?.is_completion_target &&
        !item?.target?.is_reward_collection
      ) {
        return 1
      }
      if (
        item?.target?.is_completion_target &&
        item?.target?.is_reward_collection
      ) {
        return 2
      }
    },
  },
}
</script>

<style scoped lang="scss">
.top {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 1rem;
  height: 2rem;
  background: #ffffff;
  width: 100%;
  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .tab {
    width: 90%;
    margin: 0 auto;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-around;
    div {
      height: 1.4rem;
      width: 4rem;
      color: #8698b7;
    }
  }
}
.list {
  width: 90%;
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
  .listItem {
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .itemLeft {
      display: flex;
      .itemImg {
        display: flex;
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
        background: #b2eff9;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        img {
          width: 1.55rem;
          height: 2.1rem;
        }
      }
      .itemInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 0.7rem;
        text-align: start;
        .itemSend {
          font-weight: bold;
        }
        .itemWidth {
          width: 7.5rem;
          height: 0.8rem;
          border-radius: 1rem;
          background: #eaf9fc;
          border: 1px solid #39cce9;
          position: relative;
          .procWidth {
            width: 3rem;
            height: 0.8rem;
            border-radius: 1rem;
            background: #39cce9;
          }
          .proc {
            font-size: 0.6rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .itemRight {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 2.8rem;
      .itemCoin {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1rem;
        background: #daf6fa;
        border-radius: 0.5rem;
        font-size: 0.7rem;
        width: 3rem;
        font-weight: bold;
        img {
          width: 1rem;
          height: 1rem;
        }
      }
      .receive {
        background: #39cce9;
        width: 3.8rem;
        height: 1.5rem;
        border-radius: 0.5rem;
        color: white;
        font-size: 0.8rem;
        line-height: 1.5rem;
      }
      .finish {
        color: #8698b7;
        background: #dee3ed;
      }
    }
  }
}

.active {
  border-bottom: 4px solid #39cce9;
  color: black;
}
:deep(.ach-pop .van-popup) {
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  height: 2.2rem;
  min-height: unset;
}
</style>
