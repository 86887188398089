import { ref, computed, watch } from 'vue'
import $api from '@/api/api'
const $config = import.meta.env

export const commonKey = ref($config.VITE_APP_NAME)
export const commonKeyInit = (id = '') => {
  const commonKey_local = $config.VITE_APP_NAME
  const userId = window.localStorage.getItem(commonKey_local + '_userId') || id
  if (userId && commonKey.value === commonKey_local) {
    commonKey.value = commonKey.value + '_' + userId
  }
}
// tabsdata
export const tabsDataRef = ref([])
// tg init data
export const tgInitData = ref({})
// 捞瓶子的信息
export const poolBottleInfo = ref({})
// 背包信息
export const bagTotalInfo = ref({})
export const bagUserInfo = ref({})
// 用户信息
export const userTotalInfo = ref({})
export const userAvatarRef = ref('')
// 全局初始化参数
export const initParams = ref({})
export const getUserTotalInfo = async () => {
  const res = await $api.getUserInfo()
  if (res.code === 0) {
    userTotalInfo.value = res.data
    console.log(userTotalInfo.value)
  }
}
export const getUserInitInfo = async () => {
  const res = await $api.getInitParams()
  if (res.code === 0) {
    initParams.value = res.data
    console.log(initParams.value)
  }
}
// 用户福利剩余次数
export const todayRemainTImes = computed(() => {
  return initParams.value?.today_remain_times || {}
})
// 用户背包瓶子剩余次数
export const todayBottleNumInfo = computed(() => {
  return initParams.value?.bottle_item_num || {}
})

export const bottleConfig = computed(() => {
  return initParams.value?.bottle_config?.reduce((acc, item) => {
    acc[item.var_name] = {
      name: item.name,
      value: item.value,
    }
    return acc
  }, {})
})

// 瓶子列表
export const bottleListRef = ref([])
export const bottlePageRef = ref(1)

// 回复列表
export const replyListRef = ref([])
export const replyPageRef = ref(1)

// 好友列表
export const friendListRef = ref([])
export const friendPageRef = ref(1)

// 列表tab
export const listTabRef = ref(0)

// 是否开启task
export const isShowTaskPage = ref(true)

// 是否开启某些功能页面
export const isShowFeatureConfig = ref({
  luckyWheel: false,
  systemInfo: false,
  fontConfig: false,
})
// 商品列表
export const goodsListRef = ref([])

// 成就列表
export const achieveListRef = ref([])

// 个人成就
export const achieveUserListRef = computed(() => {
  return achieveListRef.value.filter((item) => item.status === 2)
})

// 个人成就待领取
export const achieveReceiveUserListRef = computed(() => {
  return achieveListRef.value.filter((item) => item.status === 1)
})

// 监听是否有未领取来显示红点
watch(
  () => achieveReceiveUserListRef.value,
  (newVal) => {
    console.log(7877777, newVal)
    if (newVal.length > 0) {
      tabsDataRef.value = tabsDataRef.value.map((item) => {
        if (item.routeName === 'achievement') {
          item.count = true
        }
        return item
      })
    } else {
      tabsDataRef.value = tabsDataRef.value.map((item) => {
        if (item.routeName === 'achievement') {
          item.count = 0
        }
        return item
      })
    }
    console.log(tabsDataRef.value, 4396)
  }
)

// 当前瓶子类型
export const bottleTypeRef = ref(1)

// 邀请列表
export const shareListRef = ref([])
// 邀请列表总人数
export const shareTotalNumRef = ref(0)
// 交友的任务字典
export const shareDicListRef = ref([])
commonKeyInit()
export default {
  commonKey,
  commonKeyInit,
  poolBottleInfo,
  bagTotalInfo,
  bagUserInfo,
  userTotalInfo,
  getUserTotalInfo,
  todayRemainTImes,
  initParams,
  getUserInitInfo,
  bottleConfig,
  tgInitData,
  bottleListRef,
  replyListRef,
  friendListRef,
  listTabRef,
  isShowTaskPage,
  goodsListRef,
  isShowFeatureConfig,
  bottlePageRef,
  replyPageRef,
  friendPageRef,
  shareDicListRef,
  achieveListRef,
}
