<template>
  <div class="direction center bottles_page bgSea">
    <div v-if="!showItem" class="ggsmd">
      <div class="top">
        <div class="title">My bottles</div>
        <div class="tab">
          <div
            @click="changeTab(index)"
            v-for="(item, index) in tab"
            :key="index"
            :class="{ active: activeTab === index }"
            :style="{ color: activeTab === index ? 'black' : '' }"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        v-show="activeTab === 0"
      >
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="No more"
          @load="onLoad"
          class="list"
        >
          <div
            class="listItem"
            v-for="(item, index) in bottleList"
            :key="index"
            @click="handleView(item, 0)"
          >
            <div class="itemImg">
              <img
                :src="
                  item.type === 1
                    ? imgs.bottle
                    : item.type === 2
                    ? imgs.friendBottle
                    : imgs.directedBottle
                "
                alt=""
              />
            </div>
            <div class="itemInfo">
              <div class="itemSend" v-show="item.type === 1">Send to: All</div>
              <div class="itemSend" v-show="item.type === 2">
                Send to: {{ item.gender === 1 ? 'man' : 'woman' }} ·
                {{ item.min_age }}~{{ item.max_age }}
              </div>
              <div class="itemSend" v-show="item.type === 3">
                Send to:
                <flag
                  class="flag"
                  :country="item.nation"
                  size="normal"
                />&ensp;·&ensp;{{ item.min_age }}~{{ item.max_age }}
              </div>
              <div class="itemTime">
                {{ calculateTimeDifference(item.created_at) }}
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <van-pull-refresh
        v-model="refreshingReply"
        @refresh="onRefreshReply"
        v-show="activeTab === 1"
      >
        <van-list
          v-model:loading="loadingReply"
          :finished="finishedReply"
          finished-text="No more"
          @load="onLoadReply"
          class="list"
        >
          <div
            class="listItem"
            v-for="(item, index) in replyList"
            :key="index"
            @click="handleView(item, 1)"
          >
            <div class="itemImg">
              <img
                :src="
                  item.bottle?.type === 1
                    ? imgs.bottle
                    : item.bottle?.type === 2
                    ? imgs.friendBottle
                    : imgs.directedBottle
                "
                alt=""
              />
            </div>
            <div class="itemInfo">
              <div class="itemSend" v-show="item.bottle?.type === 1">
                Send to: All
              </div>
              <div class="itemSend" v-show="item.bottle?.type === 2">
                Send to: {{ item.bottle?.gender === 0 ? 'man' : 'woman' }} ·
                {{ item.bottle?.min_age }}~{{ item.bottle?.max_age }}
              </div>
              <div class="itemSend" v-show="item.bottle?.type === 3">
                Send to:
                <flag
                  class="flag"
                  :country="item.bottle?.nation"
                  size="normal"
                />&ensp;·&ensp;{{ item.bottle?.min_age }}~{{
                  item.bottle?.max_age
                }}
              </div>
              <div class="itemTime">
                {{
                  calculateTimeDifference(item?.updated_at || item?.created_at)
                }}
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <van-pull-refresh
        v-model="refreshingFriend"
        @refresh="onRefreshFriend"
        v-show="activeTab === 2"
      >
        <van-list
          v-model:loading="loadingFriend"
          :finished="finishedFriend"
          finished-text="No more friends"
          @load="onLoadFriend"
          class="list"
        >
          <div
            class="listItem"
            v-for="(item, index) in friendList"
            :key="index"
            @click="goFriend(item, index)"
          >
            <img
              class="itemImg avatar_friend"
              :src="getAvatarFn(item?.tgid)"
              alt=""
            />
            <div class="itemInfo">
              <div class="itemSend">{{ item.nickname }}{{ item.send_id }}</div>
              <div class="itemTime">
                <img
                  v-show="Number(item.gender) === 1"
                  :src="imgs.men_full"
                  alt=""
                />
                <img
                  v-show="Number(item.gender) === 2"
                  :src="imgs.women_full"
                  alt=""
                />
                {{ item.age }}&ensp;
                <flag class="flag" :country="item.nation" size="normal" />
                &ensp;<span class="nation">{{
                  $g.getCountryName(item.nation)
                }}</span>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <friendItem
      v-else="showItem"
      :item="item"
      @close="handleFriendDelete"
    ></friendItem>
  </div>
</template>

<script>
import friendItem from '../../components/friendItem/friendItem.vue'
import flag from 'vue-country-flag-next'
import { getAvatar, calculateTimeDifferenceFn } from '@/config/common/dic'
import {
  poolBottleInfo,
  bottleListRef,
  replyListRef,
  friendListRef,
  listTabRef,
  bottlePageRef,
  replyPageRef,
  friendPageRef,
} from '@/config/common/total'
export default {
  components: { flag, friendItem },
  data() {
    return {
      refreshing: false,
      refreshingFriend: false,
      refreshingReply: false,
      loading: false,
      loadingFriend: false,
      loadingReply: false,
      getIndex: 1,
      getIndexFriend: 1,
      getIndexReply: 1,
      finished: false,
      finishedFriend: false,
      finishedReply: false,
      item: {
        name: 'Weile',
        gender: 0,
        age: 24,
        country: 'France',
        code: 'fr',
      },
      showItem: false,
      imgs: this.$store.state.sourceMsg,
      activeTab: 0,
      tab: ['Bottle', 'Reply', 'Friend'],
      bottleList: [],
      replyList: [],
      friendList: [],
    }
  },
  created() {
    Telegram.WebApp.BackButton.show()
    Telegram.WebApp.BackButton.onClick(() => {
      this.$router.push({ path: '/' })
    })
    Telegram.WebApp.setHeaderColor('#fff')
    document.getElementsByTagName('body')[0].style.backgroundColor = '#B2EFF9'
    this.bottleList = bottleListRef.value
    this.replyList = replyListRef.value
    this.friendList = friendListRef.value
    this.activeTab = listTabRef.value
  },
  mounted() {},
  beforeUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ACECF9'
  },
  methods: {
    getAvatarFn(id) {
      const url = getAvatar(id)
      return url
    },
    calculateTimeDifference(time) {
      return calculateTimeDifferenceFn(time)
    },
    changeTab(index) {
      this.activeTab = index
      listTabRef.value = index

      // 转换tab时重新调用加载
      if (index === 0 && this.bottleList.length === 0) {
        this.bottleList = []
        this.onRefresh()
      } else if (index === 1 && this.replyList.length === 0) {
        this.replyList = []
        this.onRefreshReply()
      } else if (index === 2 && this.friendList.length === 0) {
        this.friendList = []
        this.onRefreshFriend()
      }
      let timer = setTimeout(() => {
        clearTimeout(timer)
        const el = document.querySelector('.root_main')
        el &&
          el.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
      }, 0)
    },
    handleView(item, index) {
      console.log(item)
      if (index === 0) {
        poolBottleInfo.value = item
        this.$nextTick(() => {
          this.$router.push({ path: '/bottomItem' })
        })
      } else if (index === 1) {
        this.$api.getReplyInfo(item.bottle_id).then((res) => {
          poolBottleInfo.value = res.data
          this.$nextTick(() => {
            this.$router.push({ path: '/replyItem' })
          })
        })
      }
    },
    goFriend(item, index) {
      // console.log(item)
      this.showItem = true
      this.item = item
    },
    onLoad() {
      if (this.refreshing) {
        this.bottleList = []
        this.refreshing = false
        bottlePageRef.value = 1
      }
      console.log(this.bottleList, 'bottleList->>>>')
      let timer = setTimeout(() => {
        clearTimeout(timer)
        this.$api.getBottleList(bottlePageRef.value, 10).then((res) => {
          if (res.code === 0) {
            if (res.data.data.length > 0) {
              if (bottlePageRef.value === 1) {
                this.bottleList = res.data.data
              } else {
                this.bottleList.push(...res.data.data)
              }
              console.log('refreshing=== bottle-list>>>>=============')
              this.loading = false
              if (res.data.data.length < 10) {
                this.finished = true
              }
            } else {
              this.bottleList = bottleListRef.value
              this.finished = true
            }
            bottlePageRef.value = bottlePageRef.value + 1
          } else {
            this.finished = true
          }
          bottleListRef.value = this.bottleList
        })
      }, 0)
    },
    onLoadFriend() {
      if (this.refreshingFriend) {
        console.log(98788)
        this.friendList = []
        this.refreshingFriend = false
        friendPageRef.value = 1
      }
      let timer = setTimeout(() => {
        clearTimeout(timer)
        this.$api.getFriendsList(friendPageRef.value, 10).then((res) => {
          if (res.code === 0) {
            if (res.data.length > 0) {
              this.friendList.push(...res.data)
              console.log('merge arr==============', this.bottleList)
              friendPageRef.value = friendPageRef.value + 1
              this.loadingFriend = false
              if (res.data.length < 10) {
                this.finishedFriend = true
              }
            } else {
              this.finishedFriend = true
            }
          } else {
            this.finishedFriend = true
          }
          friendListRef.value = this.friendList
        })
      }, 0)
    },
    onLoadReply() {
      if (this.refreshingReply) {
        this.replyList = []
        this.refreshingReply = false
        replyPageRef.value = 1
      }
      let timer = setTimeout(() => {
        clearTimeout(timer)
        this.$api.getReplyBottle(replyPageRef.value, 10, 0).then((res) => {
          if (res.code === 0) {
            if (res.data.data.length > 0) {
              this.replyList.push(...res.data.data)
              replyPageRef.value = replyPageRef.value + 1
              this.loadingReply = false
              console.log('this.replyList================', this.replyList)
              if (res.data.data.length < 10) {
                this.finishedReply = true
              }
            } else {
              this.finishedReply = true
            }
          } else {
            this.finishedReply = true
          }
          replyListRef.value = this.replyList
        })
      }, 0)
    },
    onRefresh(index) {
      // 清空列表数据
      this.finished = false
      bottlePageRef.value = 1

      this.loading = true
      this.onLoad()
    },
    onRefreshFriend() {
      // 清空列表数据
      this.finishedFriend = false
      friendPageRef.value = 1

      this.loadingFriend = true
      this.onLoadFriend()
    },
    onRefreshReply() {
      // 清空列表数据
      this.finishedReply = false
      replyPageRef.value = 1

      this.loadingReply = true
      this.onLoadReply()
    },
    handleFriendDelete() {
      this.friendList = friendListRef.value
      this.showItem = false
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  // height: 100vh;
  // background-color: #B2EFF9;
}
.top {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 1rem;
  height: 4rem;
  background: #ffffff;
  width: 100%;
  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .tab {
    width: 90%;
    margin: 0 auto;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-around;
    div {
      height: 1.4rem;
      width: 4rem;
      color: #8698b7;
    }
  }
}
.list {
  width: 90%;
  margin: 0 auto;
  min-height: 90vh;
  /* height: 80vh; */
  padding-top: 5.5rem;
  padding-bottom: 5rem;
  .listItem {
    width: 85vw;
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    margin-bottom: 0.5rem;
    .itemImg {
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 0.5rem;
      background: #b2eff9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      img {
        width: 1.55rem;
        height: 2.1rem;
      }
    }
    .avatar_friend {
      background: url('/imgs/bottleType/drift_sea_logo.jpg');
      background-size: contain;
      z-index: 1;
    }
    .itemInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 0.7rem;
      text-align: start;
      .itemSend {
        height: 1rem;
        display: flex;
        align-items: center;
        .flag {
          margin-bottom: 1px;
        }
      }
      .itemTime {
        color: #8698b7;
        height: 1rem;
        display: flex;
        align-items: center;
        img {
          width: 0.7rem;
          height: 0.7rem;
          margin-right: 0.2rem;
        }
        .flag {
          margin-bottom: 1px;
          border-radius: 0.25rem;
          box-shadow: 0rem 0rem 0.25rem gray;
        }
        .nation {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 45vw;
        }
      }
    }
  }
}

.active {
  border-bottom: 4px solid #39cce9;
  color: black;
}
</style>
