import $store from '@/store/index'
import imageCompression from 'browser-image-compression'
import { userAvatarRef } from './total'
import { showToast } from 'vant'
import md5 from 'js-md5'
import $api from '@/api/api'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
const $config = import.meta.env
export const DEFAULT_AVATAR = '/imgs/bottleType/drift_sea_logo.jpg'
export function generateUniqueString() {
  // 生成UUID
  const uuid = uuidv4()

  // 通过去掉UUID中的 '-' 或其他方式来确保它是一个唯一的字符串
  const uniqueString = uuid.replace(/-/g, '')

  return uniqueString
}

console.log(generateUniqueString(), 88888888881888) // 输出唯一字符串

const defaultOptions = {
  maxSizeMB: 0.8,
  maxWidthOrHeight: 1024,
  useWebWorker: true,
}
// 封装的图片压缩函数
export async function compressImage(
  file,
  options = defaultOptions,
  exceed = 50
) {
  try {
    if (file.size > 1024 * 1024 * exceed) {
      showToast(`The file size cannot exceed ${exceed}MB`)
      return null
    }

    const compressedFile = await imageCompression(file, options)
    console.log('Original file size:', file.size / 1024 / 1024, 'MB')
    console.log(
      'Compressed file size:',
      compressedFile.size / 1024 / 1024,
      'MB'
    )

    return compressedFile
  } catch (error) {
    console.error('Error during image compression:', error)
    return null
  }
}

// 发帖字体
const fontFamilyListFN = () => {
  return [
    {
      fontFamilyId: 0,
      id: 0,
      name: 'Simple feather',
      fontFamily: 'Simple feather',
      count: 0,
      img: $store.state.sourceMsg.font1,
    },
    {
      fontFamilyId: 1,
      id: 1,
      name: 'Axure Handwriting',
      fontFamily: 'Axure Handwriting',
      count: 1,
      img: $store.state.sourceMsg.font2,
    },
    {
      fontFamilyId: 2,
      id: 2,
      name: 'soulhand writing',
      fontFamily: 'soulhand writing',
      count: 1,
      img: $store.state.sourceMsg.font3,
    },
    {
      fontFamilyId: 3,
      id: 3,
      name: 'Playlist Script',
      fontFamily: 'Playlist Script',
      count: 1,
      img: $store.state.sourceMsg.font4,
    },
    {
      fontFamilyId: 4,
      id: 4,
      name: 'While Paper',
      fontFamily: 'While Paper',
      count: 1,
      img: $store.state.sourceMsg.font5,
    },
  ]
}
// 发帖皮肤
const paperListFN = () => {
  return [
    {
      paperId: 0,
      id: 0,
      name: 'Classic',
      count: 0,
      img: $store.state.sourceMsg.paper1,
    },
    {
      paperId: 1,
      id: 1,
      name: 'Rolled paper',
      count: 1,
      img: $store.state.sourceMsg.paper2,
    },
    {
      paperId: 2,
      id: 2,
      name: 'Pink paper',
      count: 1,
      img: $store.state.sourceMsg.paper3,
    },
    {
      paperId: 3,
      id: 3,
      name: 'old paper',
      count: 1,
      img: $store.state.sourceMsg.paper4,
    },
    {
      paperId: 4,
      id: 4,
      name: 'White paper',
      count: 1,
      img: $store.state.sourceMsg.paper5,
    },
  ]
}
export const paperList = () => paperListFN()
export const fontFamilyList = () => fontFamilyListFN()

export function formatDate(dateString) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = date.getDate()

  let daySuffix
  if (day % 10 === 1 && day !== 11) {
    daySuffix = 'st'
  } else if (day % 10 === 2 && day !== 12) {
    daySuffix = 'nd'
  } else if (day % 10 === 3 && day !== 13) {
    daySuffix = 'rd'
  } else {
    daySuffix = 'th'
  }

  return `${month} ${day}${daySuffix} ${year}`
}

async function sendMultipartRequest(url, file) {
  try {
    const formData = new FormData()
    formData.append('file', file)
    const response = await axios.post(url, formData, {
      headers: {
        // 'Content-Type': 'multipart/form-data', // 设置请求头
      },
    })
    return response?.data?.result?.variants?.[0]
  } catch (error) {
    return null
  }
}

// 下载前获得上传地址
export const getUploadUrl = async (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const selectedFile = await compressImage(file)
      console.log(selectedFile, 'compress->>>>>>')
      const res = await $api.getUploadUrl()
      console.log(res, 'remote uploadurl')
      if (res.code === 0 && res?.data?.uploadURL) {
        const result = await sendMultipartRequest(
          res?.data?.uploadURL,
          selectedFile
        )
        console.log(result, 'result url')
        result && resolve(result)
        !result && reject('')
      }
    } catch (error) {
      console.log(error, 4348898)
    }
  })
}

export const asyncGetAvatar = async (avatar) => {
  try {
    let avatarMd = md5(avatar + '')
    let url = `https://image.googlescdn.com/${avatarMd}`
    const res = await checkImageTransparency(url)
    if (res?.isTransparent || res?.isEmpty) {
      url = DEFAULT_AVATAR
    }
    userAvatarRef.value = url
    return url
  } catch (error) {
    console.log(error, 'error')
    return DEFAULT_AVATAR
  }
}
export const getAvatar = (avatar) => {
  try {
    let avatarMd = md5(avatar + '')
    const url = `https://image.googlescdn.com/${avatarMd}`
    return url
  } catch (error) {
    console.log(error, 'error')
    return DEFAULT_AVATAR
  }
}

/**
 * 检测图片是否全透明或没有任何内容。
 *
 * @param {string} imageSrc - 图片的 URL 或路径。
 * @returns {Promise<Object>} 包含透明度和内容信息的结果对象。
 *
 * 结果对象包含以下属性：
 * @property {boolean} isTransparent - 是否是全透明图片。
 * @property {boolean} hasContent - 图片是否包含内容（非全透明像素）。
 * @property {boolean} isEmpty - 图片是否是空白图片（全透明且无内容）。
 */
export function checkImageTransparency(imageSrc) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous' // 避免跨域问题
    img.src = imageSrc

    img.onload = function () {
      // 创建一个 canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // 设置 canvas 尺寸为图片大小
      canvas.width = img.width
      canvas.height = img.height

      // 将图片绘制到 canvas 上
      ctx.drawImage(img, 0, 0)

      // 获取图片的像素数据
      const imageData = ctx.getImageData(0, 0, img.width, img.height)
      const pixels = imageData.data // 像素数组

      let isTransparent = true
      let hasContent = false

      // 遍历所有像素 (每个像素由 RGBA 四个通道组成)
      for (let i = 0; i < pixels.length; i += 4) {
        const r = pixels[i] // Red
        const g = pixels[i + 1] // Green
        const b = pixels[i + 2] // Blue
        const a = pixels[i + 3] // Alpha (透明度)

        // 如果有任意一个像素的透明度不为 0，说明图片不全透明
        if (a !== 0) {
          isTransparent = false
        }

        // 如果有任意一个像素的颜色通道有值，说明图片有内容
        if (r !== 0 || g !== 0 || b !== 0) {
          hasContent = true
        }

        // 如果发现图片有内容且不透明，提前结束循环
        if (hasContent && !isTransparent) {
          break
        }
      }

      // 返回结果
      resolve({
        isTransparent,
        hasContent,
        isEmpty: isTransparent && !hasContent,
      })
    }

    img.onerror = function () {
      console.error('图片加载失败')
      reject(new Error('图片加载失败'))
    }
  })
}

export function calculateTimeDifferenceFn(time) {
  // 将带有 UTC 标记的时间字符串解析为 Date 对象
  let givenDate = new Date(time)

  // 检查解析是否成功
  if (isNaN(givenDate.getTime())) {
    console.error('Invalid date format')
    return 'Invalid date format'
  }

  let currentTime = new Date() // 当前本地时间
  let timeDiff = currentTime - givenDate // 时间差值
  let diff = ''

  if (timeDiff < 60 * 1000) {
    // 小于1分钟
    diff = `Just now`
  } else if (timeDiff >= 60 * 1000 && timeDiff < 60 * 60 * 1000) {
    // 小于60分钟
    diff = `Released ${Math.floor(timeDiff / (60 * 1000))} minutes ago`
  } else if (timeDiff >= 60 * 60 * 1000 && timeDiff < 24 * 60 * 60 * 1000) {
    // 大于60分钟，小于1天
    diff = `Released ${Math.floor(timeDiff / (60 * 60 * 1000))} hours ago`
  } else {
    // 超过1天
    diff = `Released ${Math.floor(timeDiff / (24 * 60 * 60 * 1000))} days ago`
  }

  return diff
}

export const getTgInitData = (k = '') => {
  try {
    let initData = window.Telegram.WebApp.initData
    const queryString = decodeURIComponent(initData)
    const params = new URLSearchParams(queryString)
    let result = {}
    params.forEach((value, key) => {
      if (key === 'user') {
        result = JSON.parse(value)
      }
    })

    if (k) {
      if ($config.VITE_MODEL === 'development' && !result[k]) {
        return 'test_name'
      }
      return result[k]
    }
    return result
  } catch (error) {
    console.log(error, 'getTgInitData error')
  }
}
