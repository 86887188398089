div
<template>
  <div class="direction center mission_page bgSea">
    <div class="top">
      <div class="title">Task</div>
      <div class="tab">
        <div
          @click="changeTab(index)"
          v-for="(item, index) in tab"
          :key="index"
          :class="{ active: activeTab === index }"
          :style="{ color: activeTab === index ? 'black' : '' }"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div v-if="activeTab === 0">
      <div class="Progress">
        <div class="proBg">
          <div class="proLine">
            <div
              class="nowLine"
              :style="{
                width: taskNum >= 200 ? '100%' : (taskNum / 200) * 100 + '%',
              }"
            ></div>
            <div
              class="proStart"
              :style="{ 'background-image': `url(${imgs.activity1})` }"
            >
              <div>{{ this.taskNum }}</div>
            </div>
            <div class="boxItem box1">
              <img v-if="taskNum >= 50" :src="imgs.box1_open" alt="" />
              <img v-else :src="imgs.box1" alt="" />
              <div>50</div>
            </div>
            <div class="boxItem box2">
              <img v-if="taskNum >= 100" :src="imgs.box1_open" alt="" />
              <img v-else :src="imgs.box1" alt="" />
              <div>100</div>
            </div>
            <div class="boxItem box3">
              <img v-if="taskNum >= 150" :src="imgs.box2_open" alt="" />
              <img v-else :src="imgs.box2" alt="" />
              <div>150</div>
            </div>
            <div class="boxItem box4">
              <img v-if="taskNum >= 200" :src="imgs.box3_open" alt="" />
              <img v-else :src="imgs.box3" alt="" />
              <div>200</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="listItem" v-for="(item, index) in dailyList" :key="index">
          <div class="itemLeft">
            <img class="itemImg" :src="item.img" alt="" />
            <div class="itemTitle">{{ item.title }}</div>
          </div>
          <div class="itemStatus">
            <div class="itemCoin">
              <img :src="imgs.activity2" alt="" />
              +{{ item.coin }}
            </div>
            <div class="itemSta">
              <div v-show="!item.status" class="doTask">Go</div>
              <img v-show="item.status" :src="imgs.finish" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list" v-if="activeTab === 1">
      <div class="timeTop">
        <div class="proBg">
          <div class="timeTip">
            {{
              !teskClose
                ? 'tasks in progress...'
                : 'The task has been closed,please wait for the next round to begin.'
            }}
          </div>
          <div class="goTime">
            time left:&ensp;<van-count-down :time="time" />
          </div>
        </div>
      </div>
      <div
        class="listItem"
        v-for="(item, index) in achievementList"
        :key="index"
      >
        <div class="itemLeft">
          <div class="itemImg">
            <img :src="item.url" alt="" />
          </div>
          <div class="itemInfo">
            <div class="itemSend">{{ item?.title }}</div>
            <div class="itemWidth">
              <div
                class="procWidth"
                :style="{
                  width:
                    item?.target?.completion_num >= item?.target?.number
                      ? '100%'
                      : (item?.target?.completion_num / item?.target?.number) *
                          100 +
                        '%',
                }"
              ></div>
              <div class="proc">
                {{
                  MinCount(item?.target?.completion_num, item?.target?.number)
                }}/{{ item?.target?.number }}
              </div>
            </div>
          </div>
        </div>
        <div class="itemRight">
          <div class="itemCoin">
            <img :src="imgs.coin" alt="" />+{{ item.award }}
          </div>
          <div
            class="receive"
            @click="handleBtnClick(item)"
            :class="{ finish: item.status == 2 }"
          >
            {{ statusList[item.status] }}
          </div>
        </div>
      </div>
    </div>
    <van-toast v-model:show="show" style="padding: 0">
      <van-toast
        v-model:show="isReceiveShow"
        :icon="imgs.coin"
        :message="`+ ${awardCoin}`"
        style="padding: 0"
      >
      </van-toast>
    </van-toast>
  </div>
</template>

<script>
import flag from 'vue-country-flag-next'
export default {
  components: { flag },
  data() {
    return {
      time: 30 * 60 * 1000,
      teskClose: false,
      show: false,
      sendBottleNum: 17,
      taskNum: 0,
      imgs: this.$store.state.sourceMsg,
      statusList: ['Go to', 'Receive', 'Finish'],
      activeTab: 0,
      tab: ['Daily Task', 'Periodic Task'],
      isReceiveShow: false,
      dailyList: [
        {
          id: 0,
          title: 'Join our TG channel',
          coin: 20,
          status: false,
          img: this.$store.state.sourceMsg.TG,
        },
        {
          id: 1,
          title: 'Join our TG group',
          coin: 20,
          status: false,
          img: this.$store.state.sourceMsg.group,
        },
        {
          id: 2,
          title: 'Join our TAPPPark',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.TAPPPark,
        },
        {
          id: 3,
          title: 'Authorization TG wallet',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.wallet1,
        },
        {
          id: 4,
          title: 'Throw the bottle',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.throwbottle,
        },
        {
          id: 5,
          title: 'Reply to bottle message',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.reply1,
        },
        {
          id: 6,
          title: 'Spend tokens',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.wallet2,
        },
        {
          id: 7,
          title: 'Open treasure chest',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.chest,
        },
        {
          id: 8,
          title: 'Pick up debris',
          coin: 20,
          status: true,
          img: this.$store.state.sourceMsg.debris,
        },
      ],
      achiList: [
        { count: 1, status: true },
        { count: 3, status: true },
        { count: 5, status: false },
        { count: 10, status: false },
        { count: 20, status: false },
        { count: 40, status: false },
        { count: 50, status: false },
        { count: 60, status: false },
      ],
      achievementList: [],
      // progressList:[
      //     { id:0, num:50, img:this.$store.state.sourceMsg.box1, imgOpen:this.$store.state.sourceMsg.box1_open },
      //     { id:0, num:100, img:this.$store.state.sourceMsg.box1, imgOpen:this.$store.state.sourceMsg.box1_open },
      //     { id:0, num:150, img:this.$store.state.sourceMsg.box2, imgOpen:this.$store.state.sourceMsg.box2_open },
      //     { id:0, num:200, img:this.$store.state.sourceMsg.box3, imgOpen:this.$store.state.sourceMsg.box3_open },
      // ],
    }
  },
  created() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#F7F6F6'
    Telegram.WebApp.setHeaderColor('#fff')
    // 计算当前进度
    this.proNum()
    this.getAchievementTask()
  },
  mounted() {},
  beforeUnmount() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ACECF9'
  },
  methods: {
    // 计算当前进度
    proNum() {
      let num = 0
      this.dailyList.map((item) => {
        if (item.status) {
          num = num + item.coin
        }
      })
      this.taskNum = num
    },
    changeTab(index) {
      this.activeTab = index
    },
    Receive() {
      this.show = true
    },
    getAchievementTask() {
      this.$api.achievementTask().then((res) => {
        if (res.code === 0) {
          this.achievementList = res?.data?.tasks.map((item) => {
            item.award = item?.reward[0]?.number
            item.status = this.getStatus(item)
            item.url = item?.icon_url || imgs.bottle
            return item
          })
          achieveListRef.value = this.achievementList
          console.log(this.achievementList, 'this.achievementList->>>>')
          return
        }
        return showToast(res?.message)
      })
    },
    MinCount(n, o) {
      return Math.min(n, o)
    },
    getStatus(item) {
      //['Go to', 'Receive', 'Finish'] 0 1 2
      if (!item?.target?.is_completion_target) {
        return 0
      }
      if (
        item?.target?.is_completion_target &&
        !item?.target?.is_reward_collection
      ) {
        return 1
      }
      if (
        item?.target?.is_completion_target &&
        item?.target?.is_reward_collection
      ) {
        return 2
      }
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  // height: 100vh;
  // background-color: #B2EFF9;
}
.top {
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 1rem;
  height: 4rem;
  background: #ffffff;
  width: 100%;
  .title {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .tab {
    width: 90%;
    margin: 0 auto;
    font-size: 0.85rem;
    display: flex;
    justify-content: space-around;
    div {
      height: 1.4rem;
      width: 6rem;
      color: #8698b7;
    }
  }
}
.Progress {
  width: 90%;
  margin: 0 auto;
  padding-top: 6rem;
  .proBg {
    height: 3rem;
    background-color: #ffedd5;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    .proLine {
      background-color: #aee6f1;
      position: relative;
      width: 90%;
      margin: 0 auto;
      height: 0.5rem;
      border-radius: 0.5rem;
      .nowLine {
        background-color: #39cce9;
        height: 0.5rem;
        border-radius: 0.5rem;
      }
      .proStart {
        left: -0.6rem;
        bottom: 1.9rem;
        width: 2.55rem;
        height: 2.95rem;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        div {
          position: absolute;
          bottom: 0.1rem;
          left: 0.7rem;
          font-size: 0.6rem;
          color: #ffffff;
        }
      }
      .boxItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        font-size: 0.6rem;
        color: #8698b7;
        img {
          width: 1.75rem;
          height: 1.55rem;
        }
      }
      .box1 {
        top: -1rem;
        left: 3.35rem;
      }
      .box2 {
        top: -1rem;
        left: 6.5rem;
      }
      .box3 {
        top: -1rem;
        left: 9.65rem;
      }
      .box4 {
        top: -1rem;
        left: 12.7rem;
      }
    }
  }
}
.timeTop {
  width: 100%;
  margin: 0 auto;
  padding-top: 5rem;
  .proBg {
    background-color: #ffedd5;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    .timeTip {
      font-size: 0.9rem;
      color: #8698b7;
    }
    .goTime {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
    }
  }
}
.list {
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 4rem;
  .listItem {
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .itemLeft {
      display: flex;
      justify-content: start;
      align-items: center;
    }
    .itemImg {
      width: 2.6rem;
      height: 2.6rem;
      margin-right: 0.5rem;
    }
    .itemStatus {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 2.8rem;
      .itemCoin {
        display: flex;
        align-items: center;
        height: 1rem;
        background: #daf6fa;
        border-radius: 0.5rem;
        font-size: 0.6rem;
        width: 3rem;
        img {
          width: 1rem;
          height: 1rem;
          margin-right: 0.2rem;
        }
      }
      .itemSta {
        display: flex;
        flex-direction: column;
        align-items: center;
        .doTask {
          background-color: #39cce9;
          color: #ffffff;
          font-size: 0.7rem;
          width: 3.35rem;
          height: 1.4rem;
          line-height: 1.4rem;
          border-radius: 0.5rem;
        }
        img {
          width: 1rem;
          height: 0.75rem;
        }
      }
    }
    .itemTitle {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 0.7rem;
      text-align: start;
      font-weight: bold;
    }
  }
}

.listItem {
  background: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  .itemLeft {
    display: flex;
    .itemImg {
      display: flex;
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      background: #b2eff9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      img {
        width: 1.55rem;
        height: 2.1rem;
      }
    }
    .itemInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 0.7rem;
      text-align: start;
      .itemSend {
        font-weight: bold;
      }
      .itemWidth {
        width: 7.5rem;
        height: 0.8rem;
        border-radius: 1rem;
        background: #eaf9fc;
        border: 1px solid #39cce9;
        position: relative;
        .procWidth {
          width: 3rem;
          height: 0.8rem;
          border-radius: 1rem;
          background: #39cce9;
        }
        .proc {
          font-size: 0.6rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .itemRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 2.8rem;
    .itemCoin {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1rem;
      background: #daf6fa;
      border-radius: 0.5rem;
      font-size: 0.7rem;
      width: 3rem;
      font-weight: bold;
      img {
        width: 1rem;
        height: 1rem;
      }
    }
    .receive {
      background: #39cce9;
      width: 3.8rem;
      height: 1.5rem;
      border-radius: 0.5rem;
      color: white;
      font-size: 0.8rem;
      line-height: 1.5rem;
    }
    .finish {
      color: #8698b7;
      background: #dee3ed;
    }
  }
}

.active {
  border-bottom: 4px solid #39cce9;
  color: black;
}
</style>
