<template>
  <div
    class="direction center sendTo_page bgSea"
    :style="{ 'background-image': `url(${imgs.sendBg})` }"
  >
    <div
      class="bottle"
      ref="draggableDiv"
      @touchstart="startDrag"
      @touchend="endDrag"
      @touchmove="handleDrag"
      @mousedown="startDrag"
      @mouseup="endDrag"
      @mousemove="handleDrag"
      :class="showClass ? 'ring' : ''"
    >
      <img :src="url" alt="" />
      <div class="splash" v-show="showSplash">
        <img :src="imgs.splash" alt="" />
      </div>
    </div>
    <van-dialog v-model:show="showSend" :showConfirmButton="false">
      <div class="SendModel">
        <!-- <div class="sendOff" @click="showSend = false"><img :src="imgs.off" alt=""></div> -->
        <img class="sendImg" :src="url" alt="" />
        <div class="sendTitle">Send bottle</div>
        <!-- <div class="sendContext">
                    Ok
                </div> -->
        <div class="sendBtn" @click="Ok()">Ok</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { bottleTypeRef } from '@/config/common/total'
export default {
  components: {},
  data() {
    return {
      showSplash: false,
      showSend: false,
      imgs: this.$store.state.sourceMsg,
      initialMouseY: 0,
      initialElementY: 0,
      showClass: false,
      bottleIcon: {
        1: '/imgs/bottleType/bottle.png',
        2: '/imgs/bottleType/friendBottle_big.png',
        3: '/imgs/bottleType/directedBottle_big.png',
      },
      isAgreeMouse: true,
    }
  },
  computed: {
    url() {
      return this.bottleIcon[bottleTypeRef.value]
    },
  },
  created() {
    console.log(bottleTypeRef.value, 'bottleTypeRef.value->>>>>')
    this.$store.dispatch('changeDisableScrollEvent', true)
  },
  mounted() {
    document.addEventListener('touchmove', this.preventTouchScroll, {
      passive: false,
    })
  },
  beforeUnmount() {
    document.removeEventListener('touchmove', this.preventTouchScroll)
    this.$store.dispatch('changeDisableScrollEvent', false)
  },
  methods: {
    preventTouchScroll(event) {
      event.preventDefault()
    },
    computedTop() {
      const top = this.$refs.draggableDiv.style.top
      return top.slice(0, top.length - 2)
    },
    isStartRotate() {
      const top = +this.computedTop()
      return top < 390 && top > 50
    },
    startDrag(event) {
      // 处理触摸事件和鼠标事件
      if (!event?.touches && !this.isAgreeMouse) {
        return
      }
      const touch = event.touches ? event.touches[0] : event
      const y = touch.clientY // 获取 Y 坐标
      this.initialMouseY = y
      this.initialElementY = this.$refs.draggableDiv.offsetTop

      // 如果需要停止旋转，则调用 endDrag
      this.isStartRotate() && this.endDrag()
    },
    handleDrag(event) {
      if (!event?.touches && !this.isAgreeMouse) {
        return
      }
      // 处理触摸事件和鼠标事件
      const touch = event.touches ? event.touches[0] : event

      if (
        this.initialMouseY !== undefined &&
        this.initialElementY !== undefined
      ) {
        const deltaY = touch.clientY - this.initialMouseY

        if (deltaY <= 0) {
          this.$refs.draggableDiv.style.top = `${
            this.initialElementY + deltaY
          }px`

          // 如果需要停止旋转，则调用 endDrag
          this.isStartRotate() && this.endDrag()
        }
      }
    },
    endDrag() {
      console.log(this.isStartRotate(), this.computedTop(), 999)
      this.startRotate()
    },
    startRotate() {
      this.showClass = true
      setTimeout(() => {
        this.showSplash = true
      }, 2000)
      setTimeout(() => {
        this.showSend = true
      }, 2100)
    },
    Ok() {
      this.$router.push('/')
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.bottle {
  position: absolute;
  width: 7.65rem;
  height: 11.9rem;
  animation: moveUp 0.5s linear forwards;

  img {
    width: 100%;
  }

  .splash {
    position: absolute;
    width: 2.6rem;
    height: 1.9rem;
    bottom: -0.5rem;
    left: -1rem;

    img {
      width: 100%;
    }
  }
}

.ring {
  animation-name: rotateR;
  animation-duration: 2s;
}

.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;

    img {
      width: 100%;
    }
  }

  .sendImg {
    width: 3.55rem;
    height: 4.65rem;
  }

  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }

  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }

  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}

@keyframes moveUp {
  0% {
    bottom: -6.25rem;
    left: -2rem;
  }

  100% {
    bottom: -3.25rem;
    left: -2rem;
  }
}

@keyframes rotateR {
  0% {
    transform: rotate(0deg);
    bottom: -3.25rem;
    left: -2rem;
    width: 7.65rem;
    height: 11.9rem;
    top: unset;
  }

  45% {
    transform: rotate(648deg);
    width: 4.3rem;
    height: 5.8rem;
    bottom: 41.25rem;
    left: 5.4rem;
    top: unset;
  }

  50% {
    transform: rotate(720deg);
    width: 3.8rem;
    height: 5rem;
    bottom: 100vh;
    top: unset;

    left: 6rem;
  }

  60% {
    transform: rotate(756deg);
    width: 3.3rem;
    height: 4.2rem;
    bottom: 100vh;
    top: unset;
    left: 6.6rem;
  }

  100% {
    transform: rotate(1080deg);
    bottom: 60vh;
    left: 12rem;
    width: 1.1rem;
    top: unset;
    height: 1.4rem;
    // display: none;
  }
}
</style>
