<template>
  <div class="direction center send_page bgSea">
    <div class="box">
      <div class="top">
        <div class="editItem" @click="this.$router.go(-1)">
          <img :src="imgs.off" alt="" />
        </div>
      </div>
      <div class="editBack" :style="{ 'background-image': `url(${paper})` }">
        <div class="textImg" v-show="showImg">
          <img
            class="deleteImg"
            @click="showImg = false"
            :src="imgs.off"
            alt=""
          />
          <img :src="url" alt="" />
        </div>
        <van-field
          class="textField"
          v-model="message"
          style="overflow: scroll; margin-top: 0.6rem"
          rows="2"
          autosize
          type="textarea"
          maxlength="800"
        />
      </div>
      <div class="bottomText">
        <div class="sendFriend" v-show="bottleType === 'Common'">Reply</div>
        <div class="sendTo" @click="this.showSend = true">
          <img src="/src/assets/img/editBottle/font_2.png" alt="" />
        </div>
      </div>
    </div>
    <van-dialog v-model:show="showSend" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showSend = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.sendBottle_full" alt="" />
        <div class="sendTitle">Send bottle</div>
        <div class="sendContext">
          Do you want to send the bottle?<br />
          Please comfirm that the Information<br />
          you have tilled In Is correct
        </div>
        <div class="sendBtn" @click="handleSend">Send</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { poolBottleInfo } from '@/config/common/total'
import { formatDate, fontFamilyList, paperList } from '@/config/common/dic'
export default {
  data() {
    return {
      imgs: this.$store.state.sourceMsg,
      paper: this.$store.state.sourceMsg.paper1,
      message: "Hello stranger,I'm very new to meeting you.",
      activeTab: 0,
      activeGender: 0,
      activeName: 0,
      activeFontFamily: 0,
      activePaper: 0,
      show: false,
      showFriend: false,
      showDirected: false,
      showSelectCounty: false,
      showFontFamily: false,
      showPaper: false,
      showSend: false,
      bottleType: '',
      bottleList: [
        {
          name: 'Common',
          desc: 'Send your message anonymousely in a bottle',
          count: 1,
        },
        {
          name: 'Friend',
          desc: 'Send your message anonymousely in a bottle',
          count: 1,
        },
        {
          name: 'Directed',
          desc: 'Send your message anonymousely in a bottle',
          count: 1,
        },
      ],
      genderList: ['All', 'Men', 'Women'],
      friendForm: {
        age: [24, 40],
        gender: 'All',
      },
      directedForm: {
        country: 'France',
        countryCode: 'fr',
        age: [24, 40],
      },
      fontFamilyList: [],
      paperList: [],
      showImg: false,
      url: '',
    }
  },
  computed: {
    avatarUrl() {
      return base_url + this.bottleInfo?.user_info?.avatar
    },
    paper() {
      return (
        this.paperList.find((item) => item.id === this.bottleInfo.config?.paper)
          ?.img || this.$store.state.sourceMsg.paper1
      )
    },
    familyType() {
      return (
        this.fontFamilyList.find(
          (item) => item.id === this.bottleInfo.config?.fontFamily
        )?.fontFamily || this.$store.state.sourceMsg.font1
      )
    },
  },
  created() {
    if (poolBottleInfo.value?.content) {
      this.bottleInfo = poolBottleInfo.value
      if (!this.bottleInfo?.config) {
        this.bottleInfo.config = { paper: 1, fontFamily: 0, url: '' }
      }
    }
    this.fontFamilyList = fontFamilyList()
    this.paperList = paperList()
  },
  mounted() {
    console.log(this.$store.state.sourceMsg.add)
  },
  beforeUnmount() {},
  methods: {
    onSelect(item) {
      this.show = false
      this.bottleType = item.name
    },

    confirm(index) {
      if (index === 0) {
        this.bottleType = this.activeName
        this.show = false
      } else if (index === 1) {
        this.showFriend = false
      } else {
        this.showDirected = false
      }
    },
    changeGender(item, index) {
      this.friendForm.gender = item
      this.activeGender = index
    },
    changeFontFamily(item, index) {
      this.activeFontFamily = index
    },
    changePaper(item, index) {
      this.activePaper = index
    },
    changeCountry() {
      this.showSelectCounty = true
      this.showDirected = false
    },
    selectCountry(item) {
      this.directedForm.country = item.name
      this.directedForm.countryCode = item.code
      this.showSelectCounty = false
      this.showDirected = true
    },
    closeCountry() {
      this.showSelectCounty = false
      this.showDirected = true
    },
    paperBuy() {
      this.showPaper = false
      this.paper = this.paperList[this.activePaper].img
    },
    upload() {
      this.$refs.upload.click()
    },
    change(event) {
      const selectedFile = event.target.files[0] // 获取用户选择的文件
      if (URL.createObjectURL(selectedFile)) {
        this.showImg = true
        this.url = URL.createObjectURL(selectedFile)
      }
    },
    handleSend() {
      this.$api
        .replyBottle({
          bottle_id: poolBottleInfo.value.id, //回复瓶子的ID
          belong_to: poolBottleInfo.value.send_id, //瓶子原主人的TGID
          content: this.message, //回复内容
        })
        .then((res) => {
          if (res.code === 0) {
            return this.$router.push('/sendTo')
          }
          return showToast(res?.message)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  background: linear-gradient(to bottom, #acecf9, #d6fdfa);
  height: 31vh;
}
.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  width: 90%;
  margin: 1rem auto;
  margin-bottom: 0;
  display: flex;
  justify-content: end;
  .editItem {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    margin-left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
.bottomText {
  height: 4rem;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .sendFriend {
    height: 3rem;
    width: 12rem;
    background-color: #f3f5f9;
    border-radius: 0.55rem;
    color: #8698b7;
    line-height: 3rem;
    text-align: start;
    padding-left: 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sendTo {
    height: 3rem;
    width: 4rem;
    background-color: #39cce9;
    border-radius: 0.55rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 2rem;
      width: 2rem;
    }
  }
}
.editBack {
  width: 17rem;
  height: calc(100% - 4rem - 4rem);
  padding-bottom: 0.25rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .textImg {
    position: relative;
    width: 200px;
    margin-top: 2rem;
    .deleteImg {
      position: absolute;
      top: -1rem;
      right: -1rem;
      width: 2rem;
      height: 2rem;
    }
    img {
      width: 200px;
      border-radius: 0.5rem;
    }
  }
  .textField {
    margin: 0 auto;
    padding: 0.5rem;
    height: 90%;
    padding-top: 50px;
    overflow: scroll;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
  }
}
.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;
    img {
      width: 100%;
    }
  }
  .sendImg {
    width: 3.55rem;
    height: 4.65rem;
  }
  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }
  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }
  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}
.active {
  border: 1px solid #5ed4ec;
}
.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.rotated-back {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}
</style>
