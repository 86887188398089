<template>
  <div class="flex direction center bgSea select_page">
    <div class="box">
      <div class="top">
        <div class="backRegister">
          <div class="goback" @click="gobackCountry()">
            <svg
              t="1716861734146"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3208"
              width="1rem"
              height="1rem"
            >
              <path
                d="M709.254038 957.195501c-15.286148 0-29.522384-5.878894-40.166815-16.564257L279.38971 550.240953c-1.425466-1.39272-2.537799-2.577708-3.449565-3.846608l-2.304486-2.905166c-11.362792-11.416004-16.945951-24.848944-16.945951-39.068807 0-14.475689 5.708002-28.010961 16.056698-38.110993l392.376529-388.216789c10.612709-10.467399 24.785499-16.236799 39.892569-16.236799 14.772448 0 28.726252 5.599532 39.287795 15.753799 10.497075 10.18599 16.538675 24.390503 16.538675 38.95215 0 14.382569-5.722328 28.009937-16.142655 38.366819L390.732587 505.098823 749.217215 864.526939c10.249435 10.30981 15.909341 23.781636 15.909341 37.994336 0 14.889105-6.05081 28.859281-16.999163 39.333844C737.70809 951.744349 723.878107 957.195501 709.254038 957.195501z"
                fill="#000000"
                p-id="3209"
              ></path>
            </svg>
          </div>
          <div class="topTitle">DriftSea</div>
        </div>
        <div class="searchInput">
          <van-search v-model="value" placeholder="Search" />
        </div>
      </div>
      <div class="table">
        <div class="searchTitle">SELECT YOUR COUNTRY</div>
        <div class="searchList">
          <div
            class="country"
            v-for="(item, index) in fillist"
            :key="index"
            @click="selectCountry(item)"
          >
            <div class="countryName">{{ item.name }}</div>
            <flag class="flag" :country="item.code" size="normal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityData from '../../config/country/worldCItyData.js'
import flag from 'vue-country-flag-next'
export default {
  components: {
    flag,
  },
  data() {
    return {
      value: '',
      cityData: cityData,
    }
  },
  created() {
    this.$store.dispatch('changeDisableScrollEvent', true)
    console.log('cty-info', cityData)
  },
  mounted() {},
  beforeUnmount() {
    this.$store.dispatch('changeDisableScrollEvent', false)
  },
  computed: {
    fillist() {
      // 返回过滤后的数组
      return this.cityData.filter((item) => {
        return item.name.indexOf(this.value) !== -1
      })
    },
  },
  methods: {
    selectCountry(item) {
      console.log(item)
      this.$emit('selectCountry', item)
    },
    gobackCountry() {
      this.$emit('closeCountry')
    },
  },
}
</script>

<style scoped lang="scss">
@import './select.css';
</style>
